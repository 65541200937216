/* eslint-disable */
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const PrimeSurveys = {
    install(Vue, options) {
        Vue.prototype.alertError = function (info) {
            let message = this.extractMessage(info)
            if (message === '') {
                message = 'Unknown message'
            }
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: 'Error',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                    text: message,
                },
            })
        }
        Vue.prototype.alertWarning = function (info) {
            let message = this.extractMessage(info)
            if (message === '') {
                console.log(info)
                message = 'Unknown message'
            }
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: 'Warning',
                    icon: 'AlertCircleIcon',
                    variant: 'warning',
                    text: message,
                },
            })
        }
        Vue.prototype.alertSuccess = function (info) {
            let message = this.extractMessage(info)
            if (message === '') {
                message = 'Unknown message'
            }
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: 'Success',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: message,
                },
            })
        }
        Vue.prototype.extractMessage = function (data) {
            if (typeof data === 'string') {
                return data
            }
            if (typeof data === 'object' && typeof data.message === 'string') {
                return data.message
            }
            if (
                typeof data === 'object'
                && typeof data.error === 'string'
            ) {
                return data.error
            }

            if (data.errors instanceof Array) {
                return data.errors.join('<br/>')
            }

            if (
                typeof data.errors === 'object'
                && data.errors !== null
            ) {
                data = data.errors
            }

            const messages = []
            for (const [key, value] of Object.entries(data)) {
                if (typeof value === 'string') {
                    messages.push(value)
                } else {
                    for (const item in value) {
                        if (typeof value[item] === 'string') {
                            messages.push(value[item])
                        }
                    }
                }
            }
            return messages.join('<br/>')
        }

        Vue.prototype.confirm = function (message) {
            return new Promise((resolve, reject) => this.$swal({
                text: message,
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-secondary ml-1',
                },
                showClass: {
                    popup: 'animate__animated animate__fadeIn',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            }))
        }

        Vue.prototype.copyText = function (textToCopy) {
            // navigator clipboard api needs a secure context (https)
            if (navigator.clipboard && window.isSecureContext) {
                // navigator clipboard api method'
                navigator.clipboard.writeText(textToCopy)
            } else {
                // text area method
                const textArea = document.createElement('textarea')
                textArea.value = textToCopy
                // make the textarea out of viewport
                textArea.style.position = 'fixed'
                textArea.style.left = '-999999px'
                textArea.style.top = '-999999px'
                document.body.appendChild(textArea)
                textArea.focus()
                textArea.select()
                new Promise((res, rej) => {
                    // here the magic happens
                    document.execCommand('copy') ? res() : rej()
                    textArea.remove()
                })
            }
            this.alertSuccess('Copied successfully!')
        }

        Vue.prototype.laravelPaginationPages = function (laravelPaginationData) {
            if (
                typeof laravelPaginationData === 'object'
                && laravelPaginationData !== null
                && laravelPaginationData.last_page >= 0
            ) {
                return {
                    total_page: laravelPaginationData.last_page,
                    page: laravelPaginationData.current_page,
                    from: laravelPaginationData.from,
                    to: laravelPaginationData.to,
                    total: laravelPaginationData.last_page === 1 ? laravelPaginationData.to : laravelPaginationData.total,
                }
            }

            return {
                total_page: 0,
                page: 1,
                from: 0,
                to: 0,
                total: 0,
            }
        }

        Vue.prototype.handleResponseError = function (response) {
            if (
                response instanceof Error
            ) {
                this.alertError(response.response.statusText)
                return true
            }

            return false
        },

            Vue.prototype.generateSyncObject = function (orgObject, modifiedData) {
                const object = orgObject
                for (const key in modifiedData) {
                    object[key] = modifiedData[key]
                }
                return object
            }

        Vue.prototype.uuidv4 = function () {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
                const r = Math.random() * 16 | 0
                const
                    v = c == 'x' ? r : (r & 0x3 | 0x8)
                return v.toString(16)
            })
        }

        Vue.prototype.validate = function (elementSettings, formSettings) {
            let result = true
            if (typeof elementSettings.validate === 'function') {
                result = elementSettings.validate(elementSettings, formSettings)
                return result
            }
            let rules = []
            if (elementSettings.rules instanceof Array) {
                rules = elementSettings.rules
            } else if (typeof elementSettings.rules === 'string') {
                rules = elementSettings.rules.split(',')
            } else {
                return true
            }
            if (rules.length === 0) {
                return true
            }
            for (let i = 0; i < rules.length; i++) {
                const oneRule = rules[i]
                const {value} = elementSettings
                const elementType = elementSettings.type
                switch (oneRule) {
                    case 'required':
                        if (elementType == 'select' && (value == 0 && value == '0' && value.trim() == '')) {
                            return false
                        }
                        if (typeof value === 'string' && value.trim() === '') {
                            return false
                        }
                        if (typeof value === 'object' && value === null) {
                            return false
                        }
                        if (value instanceof Array && value.length === 0) return false
                        break
                    case 'url':
                        const expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi
                        const regex = new RegExp(expression)
                        if (!value.match(regex)) {
                            return false
                        }
                        break
                    case 'int':
                        const result = !isNaN(parseInt(value, 10)) && (parseFloat(value, 10) == parseInt(value, 10))
                        if (!result) return false
                        break
                    case 'float':
                        const val = parseFloat(value)
                        if (isNaN(val)) {
                            return false
                        }
                        break
                    case 'email':
                        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        if (!emailRegex.test(value.toLowerCase())) return false
                        break
                    default:
                        throw Error(`This rule ${oneRule} is not installed yet`)
                        return false
                }
            }
            return true
        }

        Vue.prototype.refreshMenu = function () {
            this.$store.commit('auth/REFRESH_MENU')
        }

        Vue.prototype.showLoading = function () {
            document.getElementById('spinner').style.display = 'block'
        }

        Vue.prototype.hideLoading = function () {
            document.getElementById('spinner').style.display = 'none'
        }
    },
}

export {PrimeSurveys}
